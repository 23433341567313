import React from 'react';
import Cookies from 'js-cookie';

import { PREFS } from './Storage';
import { useWebclient } from './Webclient';

const AuthContext = React.createContext();

export default function AuthProvider({children}) {
    const webclient = useWebclient();

    const [state, setState] = React.useState({
        loading: true,
        authorized: false,
        is_chef: false,
        error: null,
        user: null,
        sid: localStorage.getItem(PREFS.SESSION_ID),
    });

    const login = (sid, user, cb) => {
        localStorage.setItem(PREFS.SESSION_ID, sid);
        localStorage.setItem(PREFS.USER, JSON.stringify(user));
        setState({authorized: true, is_chef: !!user.isChef, error: null, user: user, loading: false, sid: sid});
        if (cb) cb();
    };

    const logout = (cb) => {
        localStorage.removeItem(PREFS.SESSION_ID);
        localStorage.removeItem(PREFS.USER);
        setState({authorized: false, is_chef: false, error: null, user: null, loading: false, sid: ''});
        if (cb) cb();
    };

    if (state.loading) {
        let authorized = false;
        let isChef = false;
        let user = null;
        /*
        const sidCookie = Cookies.get('sid');
        const sid = sidCookie || localStorage.getItem(PREFS.SESSION_ID);
        console.log('sid', sidCookie, sid);
        if (!!sidCookie) {
            console.log('remove sid');
            Cookies.remove('sid', {
                path: '/',
                secure: true,
            });
        }
        */
        const sid = localStorage.getItem(PREFS.SESSION_ID);
        if (sid) {
            try {
                user = JSON.parse(localStorage.getItem(PREFS.USER));
            } catch(e) { }
            if (user) {
                authorized = true;
                isChef = !!user.isChef;
                webclient.setAuthorization(sid);
            }
        }
        setState({authorized: authorized, is_chef: isChef, error: null, user: user, loading: false, sid: sid});
    }

    React.useEffect(() => {
        setTimeout(() => {
//            setState({authorized: true, error: null, user: 'user', loading: false});
        }, 500);
    }, []);

    const authFuncs = {
        login: login,
        logout: logout,
        // setSessionCookie: setSessionCookie,
    };

    return (
        <AuthContext.Provider value={{...state, ...authFuncs}}>
            {state.loading ? (
                'Checking login...'
            ) : state.error ? (
                'Error'
            ) : (
                children
            )}
        </AuthContext.Provider>
    )
}

export const useAuth = () => React.useContext(AuthContext);
